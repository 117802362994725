import React from 'react';
import '../styles/components/Footer.css';

function Footer() {
  return (
    <div className='footer'>

    </div>
  );
}

export default Footer;